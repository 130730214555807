<template>
    <div class="ideas__tranding">
        <div class="container">
            <div
                v-if="trends.length"
                class="d-flex align-items-center justify-content-between"
            >
                <div class="ideas__tranding--title">
                    {{ $t('Trending and popular ideas') }}
                </div>
                <div>
                    <div class="ideas__counter">
                        {{ currentSlide }} / {{ trends.length }}
                    </div>
                    <div class="ideas__nav">
                        <ul class="list-inline">
                            <li>
                                <button
                                    @click.prevent="slidePrev"
                                    class="ideas__nav--prev"
                                >
                                    <img
                                        loading="lazy"
                                        src="@/assets/images/banner-arrow.svg"
                                        alt=""
                                    />
                                </button>
                            </li>
                            <li>
                                <button
                                    @click.prevent="slideNext"
                                    class="ideas__nav--next"
                                >
                                    <img
                                        loading="lazy"
                                        src="@/assets/images/banner-arrow.svg"
                                        alt=""
                                    />
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <hooper
            @slide="updateCarousel"
            :settings="hooperSettings"
            ref="carousel"
        >
            <slide
                class="ideas__slide"
                v-for="(trend, index) in trends"
                :key="index"
                :index="index"
            >
                <div class="ideas__slide--image">
                    <img
                        v-if="getPreviewImage(trend)"
                        loading="lazy"
                        :src="getPreviewImage(trend)"
                        alt=""
                    />
                    <div class="ideas__slide--caption">
                        <p>
                            <router-link
                                :to="{
                                    name: 'idea-details',
                                    params: { uuid: trend.uuid },
                                }"
                            >
                                {{ trend.title }}
                            </router-link>
                        </p>
                        <!-- <div class="ideas__slide--reviews">
                            <base-reviews
                                v-if="trend.id"
                                :object="trend"
                                @social="social"
                                :to="{
                                    name: 'user-idea-details',
                                    params: { uuid: trend.uuid },
                                }"
                            ></base-reviews>
                        </div> -->
                        <p class="opco">{{ trend.country }}</p>
                        <div class="ideators">
                            <p class="ideator">
                                {{ getIdeatorsList(trend) }}
                            </p>
                        </div>
                    </div>
                </div>
                <router-link
                    :to="{
                        name: 'idea-details',
                        params: { uuid: trend.uuid },
                    }"
                    class="abs-link"
                >
                    {{ trend.title }}
                </router-link>
            </slide>
        </hooper>
    </div>
</template>
<script>
import { ideasReviews } from '@/modules/ideas/mixins/ideasReviews.js'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
    mixins: [ideasReviews],
    components: {},
    data() {
        return {
            hooperSettings: {
                wheelControl: false,
                itemsToShow: 3,
                centerMode: false,
                infiniteScroll: false,
                itemsToSlide: 1,
                breakpoints: {
                    992: {
                        itemsToShow: 3,
                    },
                    768: {
                        itemsToShow: 2,
                    },
                    576: {
                        itemsToShow: 2,
                        centerMode: true,
                    },
                    0: {
                        itemsToShow: 1,
                        centerMode: true,
                        centerPadding: '10%',
                    },
                },
            },
            currentSlide: 1,
            trends: [],
        }
    },
    methods: {
        updateCarousel() {},
        slidePrev() {
            if (this.currentSlide == 1) {
                this.currentSlide = this.trends.length
                this.$refs.carousel.slideTo(this.trends.length)
            } else {
                this.$refs.carousel.slidePrev()
                this.currentSlide--
            }
        },
        slideNext() {
            if (this.currentSlide == this.trends.length) {
                this.currentSlide = 1
                this.$refs.carousel.slideTo(0)
            } else {
                this.$refs.carousel.slideNext()
                this.currentSlide++
            }
        },
        getPreviewImage(trend) {
            return trend.preview_image
                ? trend.preview_image
                : trend.medias.length
                ? trend.medias[0].file
                : ''
        },
        getIdeatorsList(idea) {
            const ideators = [idea?.owner_details?.display_name || ''].concat(
                idea.members.map(member => {
                    return member?.user_detail?.display_name || ''
                }),
            )
            const filteredIdeators = ideators.filter(ideator => ideator)
            return filteredIdeators.join(', ')
        },
    },
    computed: {
        ...mapGetters(['ideasList', 'ideasTrendList']),
    },
    watch: {
        ideasTrendList() {
            this.trends = cloneDeep(this.ideasTrendList.results)
        },
    },
}
</script>
<style lang="scss" scoped>
.ideas {
    &__tranding {
        padding-top: rem(170px);
        .d-flex {
            margin-bottom: rem(20px);
        }
        &--title {
            font-size: rem(52px);
            color: var(--white);
            font-weight: 900;
            // text-transform: uppercase;
        }
        .hooper {
            padding-left: calc((100% - 1180px) / 2);
            position: relative;
            // &:before{
            //     content:"";
            //     position:absolute;
            //     width:100%;
            //     height:500px;
            //     left:0;
            //     bottom:-160px;
            //     background:#f6f6f6;
            // }
        }
    }
    &__nav {
        position: relative;
        ul {
            flex-wrap: nowrap;
        }
        button {
            background: transparent;
            border: 0px;
            img {
                width: 28px;
            }
            @media screen and (min-width: 1025px) {
                &:hover {
                    opacity: 0.7;
                }
            }
        }
        li:not(:last-child) {
            position: relative;
            padding-right: 10px;
            margin-right: 10px;
            &:after {
                position: absolute;
                content: '';
                width: 1px;
                height: 35px;
                background: var(--white);
                @include center-vertical();
                right: 0px;
            }
        }
        &--prev {
            img {
                transform: rotate(180deg);
            }
        }
    }
    &__slide {
        padding: 0px rem(0px);
        position: relative;
        max-width: 615px !important;
        transition: all 0.5s ease;

        $self: &;

        &--image {
            margin: rem(20px) rem(20px);
            position: relative;
            box-shadow: -5px 5px 9px rgba(0, 0, 0, 0.35);
            //min-height: 350px;
            height: 400px;
            overflow: hidden;
            text-align: center;
            &:after,
            &:before {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                content: '';
                background: rgb(0, 0, 0);
                background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 1) 0%,
                    rgba(0, 0, 0, 1) 7%,
                    rgba(255, 255, 255, 0) 100%
                );
                z-index: 1;
            }
            &:before {
                z-index: 0;
                background: rgba(0, 0, 0, 0.4);
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &--caption {
            position: absolute;
            bottom: rem(40px);
            left: 0;
            right: 0;
            margin: auto;
            padding: rem(0px) rem(35px);
            text-align: center;
            z-index: 2;
            transition: all 0.5s ease;
            color: var(--white);
            z-index: 10;
            a {
                text-decoration: none !important;
                color: var(--white);
                @media screen and (min-width: 1025px) {
                    &:hover {
                        color: var(--primary);
                    }
                }
            }
            .ideators {
                display: flex;
            }
            p {
                font-size: rem(32px);
                font-weight: 600;
                text-align: left;
                position: relative;
                transition: 0.5s ease-in all;
                top: 0;
                &.opco {
                    color: var(--secondary);
                    font-size: 1.3rem;
                    margin-bottom: 10px;
                }
                &.ideator {
                    color: var(--secondary);
                    font-size: 1.3rem;
                    margin-bottom: 0px;
                }
            }
        }
        @media screen and (min-width: 1025px) {
            &:hover {
                .ideas__slide--caption {
                    p {
                        top: -14px;
                    }
                }
            }
        }
    }
    &__counter {
        font-size: rem(32px);
        color: var(--white);
        font-weight: 700;
        margin-bottom: rem(20px);
        text-align: right;
    }
    @media screen and (max-width: 1600px) {
        &__slide {
            &--caption {
                padding: 0 rem(16px);
            }
        }
    }
    @media screen and (max-width: 1199px) {
        &__tranding {
            padding-top: rem(130px);
            .hooper {
                padding-left: calc((100% - 990px) / 2) !important;
            }
        }
        &__slide {
            max-width: 450px !important;
            &--caption {
                p {
                    font-size: rem(26px);
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        &__tranding {
            .hooper {
                padding-left: calc((100% - 768px) / 2) !important;
            }
            &--title {
                font-size: rem(40px);
            }
        }
    }
    @media screen and (max-width: 767px) {
        &__tranding {
            .d-flex {
                flex-direction: column;
            }
            &--title {
                margin-bottom: rem(25px);
                font-size: rem(32px);
            }
            .hooper {
                padding-left: 0 !important;
            }
        }
        &__counter {
            text-align: center;
        }
    }
    @media screen and (max-width: 575px) {
        &__slide {
            max-width: 100% !important;
        }
        &__tranding {
            &--title {
                font-size: rem(28px);
            }
        }
    }
}
/deep/.likes-wrapper {
    ul {
        justify-content: space-between;
        li {
            a {
                color: #fff !important;
                svg {
                    color: #fff !important;
                    height: 40px;
                }
                @media screen and (min-width: 1025px) {
                    &:hover {
                        color: #009b74 !important;
                        svg {
                            color: #009b74 !important;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1600px) {
        ul {
            li {
                &:not(:last-child) {
                    margin-right: rem(16px);
                }
                a {
                    svg {
                        height: 30px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1199px) and (min-width: 992px) {
        ul {
            li {
                &:not(:last-child) {
                    margin-right: rem(10px);
                }
                a {
                    font-size: rem(18px);
                    span {
                        padding-left: 6px;
                    }
                    svg {
                        height: 22px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        ul {
            li {
                &:not(:last-child) {
                    margin-right: rem(10px);
                }
                a {
                    font-size: rem(18px);
                    span {
                        padding-left: 6px;
                    }
                    svg {
                        height: 22px;
                    }
                }
            }
        }
    }
}

// ar style

.ar {
    .ideas {
        &__tranding {
            .hooper {
                padding-right: calc((100% - 1180px) / 2);
                padding-left: 0;
            }
        }
        &__nav {
            position: relative;
            li:not(:last-child) {
                padding-left: 10px;
                margin-left: 10px;
                padding-right: 0;
                margin-right: 0;
                &:after {
                    left: 0px;
                    right: auto;
                }
            }
            &--prev {
                img {
                    transform: rotate(0);
                }
            }
            &--next {
                img {
                    transform: rotate(180deg);
                }
            }
        }
        &__slide {
            $self: &;
        }
        &__counter {
            font-size: rem(32px);
            color: var(--white);
            font-weight: 700;
            margin-bottom: rem(20px);
            text-align: left;
            direction: ltr;
        }
        @media screen and (max-width: 1199px) {
            &__tranding {
                .hooper {
                    padding-right: calc((100% - 990px) / 2) !important;
                    padding-left: 0 !important;
                }
            }
        }
        @media screen and (max-width: 991px) {
            &__tranding {
                .hooper {
                    padding-right: calc((100% - 768px) / 2) !important;
                    padding-left: 0 !important;
                }
            }
        }
        @media screen and (max-width: 767px) {
            &__tranding {
                .hooper {
                    padding-right: 0 !important;
                }
            }
            &__counter {
                text-align: center;
            }
        }
    }
}
</style>

<style scoped>
@media (max-width: 768px) {
    .ideas__slide--image {
        height: 250px;
    }
}
</style>
