<template>
    <div>
        <form class="filters">
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="control-label">{{
                            $t('Filter by date')
                        }}</label>
                        <vuejsDatepicker
                            @input="changeFilters"
                            v-model="date"
                            :bootstrap-styling="true"
                            :clear-button="true"
                        ></vuejsDatepicker>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="control-label">{{
                            $t('Filter by opco')
                        }}</label>
                        <v-select :options="countries" v-model="selectedOpco">
                        </v-select>
                        <div
                            v-if="selectedOpco.value"
                            @click="clearOpcoAndCategory"
                            class="clear"
                        >
                            <svg
                                fill="#009b74"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 409.81 409.81"
                            >
                                <use xlink:href="#menu-close-icon"></use>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div
                        class="form-group"
                        :class="{ 'disable-filter': !selectedOpco.value }"
                    >
                        <label class="control-label">{{
                            $t('Filter by category')
                        }}</label>
                        <b-dropdown
                            :text="selectedCategoryValue"
                            class="form-control"
                        >
                            <!-- <b-dropdown-item
                                v-for="(cat, key) in categories"
                                :key="key"
                                @click="changeCategory(cat)"
                            >
                                <div
                                    class="disable-filter"
                                    style="margin-left: 1rem;"
                                >
                                    {{ cat.text }}
                                </div>
                                <div
                                    v-for="(title, index) in cat.title"
                                    :key="index"
                                    style="margin-left: 3rem;"
                                    :disabled="title.disabled"
                                    :class="{
                                        'disable-filter': title.disabled,
                                    }"
                                >
                                    {{ title.subtext }}
                                </div>
                            </b-dropdown-item> -->
                            <b-dropdown-group
                                v-for="(cat, key) in categories"
                                :key="key"
                                :header="cat.text"
                                :class="{
                                    'display-none': cat.disabled,
                                }"
                            >
                                <b-dropdown-item
                                    v-for="(title, index) in cat.title"
                                    :key="index"
                                    class="sub-category"
                                    :disabled="title.disabled"
                                    :class="{
                                        'display-none': title.disabled,
                                    }"
                                    @click="changeCategory(cat, title.subtext)"
                                >
                                    {{ title.subtext }}
                                </b-dropdown-item>
                            </b-dropdown-group>
                        </b-dropdown>
                    </div>
                </div>
                <div v-if="showStatusPicker" class="col-sm-6">
                    <div class="form-group">
                        <label class="control-label">{{
                            $t('By Idea Status')
                        }}</label>
                        <v-select
                            :options="status"
                            placeholder=""
                            v-model="statusValue"
                        ></v-select>
                        <div
                            v-if="statusValue.value"
                            @click="clear"
                            class="clear"
                        >
                            <svg
                                fill="#009b74"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 409.81 409.81"
                            >
                                <use xlink:href="#menu-close-icon"></use>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { IdeasHelper } from '@/common/crud-helpers/ideas'
import cloneDeep from 'lodash/cloneDeep'
import vuejsDatepicker from 'vuejs-datepicker'
import { mapGetters } from 'vuex'

export default {
    components: {
        vuejsDatepicker,
    },
    props: {
        showStatusPicker: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            status: [
                {
                    text: this.$t('Nothing selected'),
                    value: '',
                    disabled: true,
                },
                { value: 'new', text: this.$t('New') },
                { value: 'approved', text: this.$t('Idea Approved') },
                { value: 'workshop', text: this.$t('Sprint') },
                { value: 'mentorship', text: this.$t('Idea Mentorship') },
                { value: 'pitching', text: this.$t('Pitching') },
                { value: 'rejected', text: this.$t('Rejected') },
                { value: 'funded', text: this.$t('Funded') },
                { value: 'resubmit', text: this.$t('Idea Resubmit') },
            ],
            // statusValue: '',
            statusValue: {
                text: this.$t('Nothing selected'),
                value: '',
                en_text: 'Nothing selected',
            },
            date: '',
            countries: [
                {
                    text: this.$t('Select opco'),
                    value: '',
                    en_text: 'Select opco',
                    disabled: true,
                },
                {
                    value: '@zain.com',
                    en_text: 'Zain Group',
                    text: this.$t('Zain Group'),
                    disabled: true,
                },
                {
                    value: '@bh.zain.com',
                    en_text: 'Bahrain',
                    text: this.$t('Bahrain'),
                    disabled: true,
                },
                {
                    value: '@iq.zain.com',
                    en_text: 'Iraq',
                    text: this.$t('Iraq'),
                    disabled: true,
                },
                {
                    value: '@jo.zain.com',
                    en_text: 'Jordan',
                    text: this.$t('Jordan'),
                    disabled: true,
                },
                {
                    value: '@sa.zain.com',
                    en_text: 'KSA',
                    text: this.$t('KSA'),
                    disabled: true,
                },
                {
                    value: '@kw.zain.com',
                    en_text: 'Kuwait',
                    text: this.$t('Kuwait'),
                    disabled: true,
                },
                {
                    value: '@omantel.om',
                    en_text: 'Omantel',
                    text: this.$t('Omantel'),
                    disabled: true,
                },
                {
                    value: '@ss.zain.com',
                    en_text: 'South Sudan',
                    text: this.$t('South Sudan'),
                    disabled: true,
                },
                {
                    value: '@sd.zain.com',
                    en_text: 'Sudan',
                    text: this.$t('Sudan'),
                    disabled: true,
                },
                {
                    value: '@zc.zain.com',
                    en_text: 'Zain Cash',
                    text: this.$t('Zain Cash'),
                    disabled: true,
                },
                {
                    value: '@zaintech.com',
                    en_text: 'ZainTech',
                    text: this.$t('ZainTech'),
                    disabled: true,
                },
            ],
            selectedOpco: {
                en_text: 'Select opco',
                text: this.$t('Select opco'),
                value: '',
            },
            categories: [],
            // bucket_display: {},
            activeCategories: [],
            opcoChoices: [],
            selectedCategory: {},
            selectedCategoryValue: this.$t('Select category'),
        }
    },
    async created() {
        // this.bucketCategoriesList.results.forEach(bucket => {
        //     this.bucket_display[bucket.name_en] = bucket.slug
        // })
        await IdeasHelper.getActiveIdeaCategories()
        // await IdeasHelper.getActiveOpcoChoices()
    },
    methods: {
        changeFilters() {
            const date = new Date(this.date)
            var mm = date.getMonth() + 1
            var dd = date.getDate()
            var yy = date.getFullYear()
            this.$emit('input', {
                date: this.date ? `${yy}-${mm}-${dd}` : '',
                status: this.statusValue.value,
                idea_country: this.selectedOpco.value
                    ? this.selectedOpco.en_text
                    : '',
                bucket: this.selectedCategory.subtext,
            })
        },
        clear() {
            this.statusValue = {
                text: this.$t('Nothing selected'),
                value: '',
                en_text: 'Nothing selected',
            }
        },
        clearOpcoAndCategory() {
            this.selectedOpco = {
                text: this.$t('Select opco'),
                value: '',
                en_text: 'Select opco',
            }
            this.selectedCategory = {}
            this.selectedCategoryValue = this.$t('Select category')
            this.categories = []
            this.changeFilters()
        },
        setOpcoList() {
            this.activeCategories.forEach(cat => {
                if (cat.has_active_ideas) {
                    cat.opco_category.forEach(opco => {
                        let contry = this.countries.find(
                            country =>
                                country.en_text === opco.country_display &&
                                opco.has_active_ideas,
                        )
                        if (contry) contry.disabled = false
                    })
                }
            })
        },
        setCategoryList() {
            this.categories = []
            this.activeCategories.forEach(cat => {
                let temp = {
                    text: cat.bucket_display,
                    en_text: cat.bucket_display,
                    // value: this.bucket_display[cat.bucket_display],
                    title: [],
                }
                if (cat.has_active_ideas) {
                    let includeOpco = cat.opco_category.find(
                        opco =>
                            opco.country_display == this.selectedOpco.en_text,
                    )
                    let alreadyExistedCategory = this.categories.find(
                        categry => categry.en_text === cat.bucket_display,
                    )
                    if (alreadyExistedCategory !== undefined) {
                        alreadyExistedCategory.title.push({
                            subtext: cat.title,
                            disabled:
                                cat.has_active_ideas &&
                                includeOpco &&
                                includeOpco.has_active_ideas
                                    ? false
                                    : true,
                        })
                    } else if (includeOpco) {
                        temp.title.push({
                            subtext: cat.title,
                            disabled:
                                cat.has_active_ideas &&
                                includeOpco &&
                                includeOpco.has_active_ideas
                                    ? false
                                    : true,
                        })
                        this.categories.push(temp)
                    }
                }
            })
            this.categories.forEach(category => {
                const allTitlesDisabled = category.title.every(
                    title => title.disabled === true,
                )
                if (allTitlesDisabled) category.disabled = true
                else category.disabled = false
            })
            this.sortCategories()
            this.selectedCategory = {}
            this.selectedCategoryValue = this.$t('Select category')
        },
        sortCategories() {
            const orderMapping = {}
            this.bucketCategoriesList.results.forEach((category, index) => {
                orderMapping[category[`name_${this.$i18n.locale}`]] = index
            })

            const sortedCategories = this.categories.slice().sort((a, b) => {
                const orderA = orderMapping[a.text]
                const orderB = orderMapping[b.text]
                if (orderA === undefined) return 1
                if (orderB === undefined) return -1
                return orderA - orderB
            })
            this.categories = []
            this.categories = sortedCategories
        },

        changeCategory(val, subtext) {
            this.selectedCategory = val
            this.selectedCategory.subtext = subtext
            this.selectedCategoryValue = this.selectedCategory.text
            this.changeFilters()
        },
    },
    computed: {
        ...mapGetters([
            'activeIdeaCategories',
            'activeOpcoChoices',
            'bucketCategoriesList',
        ]),
    },

    watch: {
        activeIdeaCategories() {
            this.activeCategories = cloneDeep(this.activeIdeaCategories.results)
            this.setOpcoList()
        },
        // activeOpcoChoices() {
        //     this.opcoChoices = cloneDeep(this.activeOpcoChoices.results)
        // },
        statusValue() {
            this.changeFilters()
        },
        selectedOpco() {
            this.setCategoryList()
        },
    },
}
</script>

<style lang="scss" scoped>
/deep/ .b-dropdown {
    background: white !important;
    .dropdown-menu {
        box-shadow: -2px 0px 20px rgba(0, 0, 0, 0.15) !important;
        max-height: 20rem !important;
        overflow-y: auto;
        width: 100%;
        li {
            .sub-category {
                padding-left: 22px;
            }
        }
        .dropdown-item {
            &:hover {
                background-color: var(--primary) !important;
            }
        }
        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-track {
            background: #b4b4b4;
        }
        &::-webkit-scrollbar-thumb {
            background: linear-gradient(
                180deg,
                var(--primary) 0%,
                var(--secondary) 70%
            ) !important;
        }
        .dropdown-header {
            font-size: 1.125rem;
            font-weight: 600;
            padding: 7.2px 15px;
        }
    }
    .dropdown-toggle {
        color: var(--primary);
        text-align: start;
        text-transform: none;
        &::after {
            top: 62%;
            right: 20px;
            width: 17px;
            height: 13px;
        }
    }
    .dropdown-item {
        font-size: 1.125rem;
        font-weight: 600;
        white-space: normal;
    }
}
.disable-filter {
    pointer-events: none;
    opacity: 0.5;
}
.display-none {
    display: none;
}
/deep/ .v-dropdown-container {
    ul {
        max-height: 20rem !important;
        margin-right: 3px;
        li {
            margin-right: 2px;
            margin-left: 2px;
        }
        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-track {
            background: #b4b4b4;
        }
        &::-webkit-scrollbar-thumb {
            background: linear-gradient(
                180deg,
                var(--primary) 0%,
                var(--secondary) 70%
            ) !important;
        }
    }
}
.filters {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > div {
        flex: auto;
        max-width: 100%;
    }

    .form-group {
        margin: 0;
        .control-label {
            padding-left: 3px;
            padding-bottom: rem(20px);
            font-size: rem(22px);
            color: #313131;
        }
        .clear {
            width: 13px;
            position: absolute;
            top: 60px;
            right: 80px;
            cursor: pointer;
        }
        /deep/ .v-select-toggle {
            padding: rem(10px) rem(60px) rem(10px) rem(40px) !important;
            height: 56px;
            display: flex;
            align-items: center;
            color: var(--primary) !important;
            font-size: rem(18px) !important;
            box-shadow: 0px 0px 12px rgb(214, 214, 214);
            .arrow-down {
                right: rem(32px);
                zoom: 0.6;
            }
            &:active,
            &:focus {
                color: #fff !important;
            }
            // @media screen and (min-width: 1025px) {
            //     &:hover {
            //         color: #fff !important;
            //     }
            // }
        }
        /deep/.vdp-datepicker {
            .form-control {
                height: 56px;
            }
        }
    }
    @media screen and (max-width: 1300px) {
        .form-group {
            /deep/.v-select-toggle {
                padding-left: rem(20px);
            }
        }
    }
    @media screen and (max-width: 1199px) {
        justify-content: center;
        padding: rem(30px) 0;
    }
    @media screen and (max-width: 575px) {
        .form-group {
            margin-bottom: rem(25px);
        }
    }
    /deep/ {
        @media screen and (max-width: 991px) {
            .clear {
                right: 60px;
            }
        }
    }
}

// ar style

.ar {
    .filters {
        /deep/ {
            .clear {
                left: 80px;
                right: auto;
            }
            @media screen and (max-width: 991px) {
                .clear {
                    left: 60px;
                    right: auto;
                }
            }
        }
        .form-group {
            /deep/ .b-dropdown {
                .dropdown-toggle {
                    &::after {
                        right: auto;
                        left: 20px;
                    }
                }
                .dropdown-header {
                    text-align: right;
                    padding: 7.2px 15px;
                }
                .dropdown-menu {
                    li {
                        .sub-category {
                            padding-right: 22px;
                            padding-left: 2px;
                        }
                    }
                }
            }
            .control-label {
                padding-right: 3px;
                padding-left: 0;
            }
            /deep/ .v-select-toggle {
                padding: rem(10px) rem(28px) rem(10px) rem(40px) !important;
                .arrow-down {
                    left: rem(32px);
                    right: auto;
                }
            }
            /deep/.vdp-datepicker {
                .form-control {
                    direction: ltr;
                    text-align: right;
                }
            }
        }
        @media screen and (max-width: 1300px) {
            .form-group {
                /deep/.v-select-toggle {
                    padding-right: rem(20px);
                    padding-left: 0;
                }
            }
        }
    }
}
</style>
