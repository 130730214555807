var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
        '--bannerHeight':
            _vm.ideasTrendList &&
            _vm.ideasTrendList.results &&
            _vm.ideasTrendList.results.length
                ? '750px'
                : '560px',
    })},[_c('base-inner-banner',{attrs:{"banner-image":_vm.pageBanner}}),_c('base-sections-scroll',{attrs:{"page-sections":_vm.pageSections}}),_c('div',{staticClass:"ideas"},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"container",attrs:{"id":"introduction"}},[_c('base-page-head',{attrs:{"page-head":_vm.pagehead}})],1),_c('div',{staticClass:"ideas__tranding"},[_c('trending-ideas')],1),_c('div',{staticClass:"page-contents",class:{
                    'no-slider':
                        !_vm.ideasTrendList ||
                        !_vm.ideasTrendList.results ||
                        !_vm.ideasTrendList.results.length,
                }},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"ideas__latest",attrs:{"id":"latest-ideas"}},[_c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"greyBg"}),_c('div',{staticClass:"row align-items-end ",style:(_vm.ideas.length
                                        ? ''
                                        : { 'padding-bottom': '22rem' })},[_c('div',{staticClass:"col-xl-4"},[_c('h3',{staticClass:"section-title"},[_vm._v(" "+_vm._s(_vm.$t('Latest Ideas'))+" ")]),_c('p',{staticClass:"section-subtitle"},[_vm._v(" "+_vm._s(_vm.$t( "We love turning ideas into reality. We're always on the lookout for the next big idea, tell us yours…" ))+" ")])]),_c('div',{staticClass:"col-xl-8"},[(
                                            _vm.bucketCategoriesList &&
                                                _vm.bucketCategoriesList.count
                                        )?_c('filters',{on:{"input":_vm.changeFilters}}):_vm._e()],1)])]),_vm._l((_vm.ideas),function(idea,index){return _c('div',{key:index,staticClass:"ideas__latest--rowWrapper"},[_c('div',{staticClass:"greyBg"}),_c('div',{staticClass:"ideas__latest--row"},_vm._l((idea),function(i){return _c('div',{key:i.id,staticClass:"ideas__latest--items"},[_c('ideas-card',{attrs:{"idea":i,"description":true,"date":false,"to":{
                                            name: 'idea-details',
                                            params: { uuid: i.uuid },
                                        },"showReviews":false,"showIdeators":true,"showOpco":true,"showCategory":false}})],1)}),0)])}),(_vm.loadding)?_c('base-loader'):_vm._e()],2)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }