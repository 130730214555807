<template>
    <div
        :style="{
            '--bannerHeight':
                ideasTrendList &&
                ideasTrendList.results &&
                ideasTrendList.results.length
                    ? '750px'
                    : '560px',
        }"
    >
        <!-- Banner -->
        <base-inner-banner :banner-image="pageBanner"></base-inner-banner>
        <!-- Banner -->

        <!-- Scroll Spy -->
        <base-sections-scroll
            :page-sections="pageSections"
        ></base-sections-scroll>
        <!-- Scroll Spy -->

        <!-- Content wrapper -->
        <div class="ideas">
            <div class="content-wrapper">
                <div class="container" id="introduction">
                    <!-- Page Head -->
                    <base-page-head :page-head="pagehead"></base-page-head>
                    <!-- Page Head -->
                </div>
                <!-- <div class="ideas"> -->
                <div class="ideas__tranding">
                    <!-- trendingideas -->
                    <trending-ideas></trending-ideas>
                    <!-- trendingideas -->
                </div>
                <div
                    class="page-contents"
                    :class="{
                        'no-slider':
                            !ideasTrendList ||
                            !ideasTrendList.results ||
                            !ideasTrendList.results.length,
                    }"
                >
                    <div class="container">
                        <div class="ideas__latest" id="latest-ideas">
                            <div class="position-relative">
                                <div class="greyBg"></div>
                                <div
                                    class="row align-items-end "
                                    :style="
                                        ideas.length
                                            ? ''
                                            : { 'padding-bottom': '22rem' }
                                    "
                                >
                                    <div class="col-xl-4">
                                        <h3 class="section-title">
                                            {{ $t('Latest Ideas') }}
                                        </h3>
                                        <p class="section-subtitle">
                                            {{
                                                $t(
                                                    `We love turning ideas into reality. We're always on the lookout for the next big idea, tell us yours…`,
                                                )
                                            }}
                                        </p>
                                    </div>
                                    <div class="col-xl-8">
                                        <!-- ideafilters -->
                                        <filters
                                            v-if="
                                                bucketCategoriesList &&
                                                    bucketCategoriesList.count
                                            "
                                            @input="changeFilters"
                                        ></filters>
                                        <!-- ideafilters -->
                                    </div>
                                </div>
                            </div>
                            <div
                                class="ideas__latest--rowWrapper"
                                v-for="(idea, index) in ideas"
                                :key="index"
                            >
                                <div class="greyBg"></div>
                                <div class="ideas__latest--row">
                                    <div
                                        class="ideas__latest--items"
                                        v-for="i in idea"
                                        :key="i.id"
                                    >
                                        <ideas-card
                                            :idea="i"
                                            :description="true"
                                            :date="false"
                                            :to="{
                                                name: 'idea-details',
                                                params: { uuid: i.uuid },
                                            }"
                                            :showReviews="false"
                                            :showIdeators="true"
                                            :showOpco="true"
                                            :showCategory="false"
                                        />
                                    </div>
                                </div>
                            </div>
                            <base-loader v-if="loadding" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imgMedium from '@/assets/images/banners/ideas-banner.jpg'
import imgLarge from '@/assets/images/banners/ideas-banner.jpg'
import imgBig from '@/assets/images/banners/ideas-banner.jpg'
import { IdeasHelper } from '@/common/crud-helpers/ideas'
import Filters from '@/modules/ideas/components/list/Filters'
import IdeasCard from '@/modules/ideas/components/list/IdeasCard'
import TrendingIdeas from '@/modules/ideas/components/list/TrendingIdeas'
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
    components: {
        TrendingIdeas,
        Filters,
        IdeasCard,
    },
    data() {
        return {
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
                imgAlt: this.$t('Ideas'),
            },
            pageSections: [
                {
                    title: this.$t('Introduction'),
                    id: 'introduction',
                },
                {
                    title: this.$t('Latest Ideas'),
                    id: 'latest-ideas',
                },
            ],
            pagehead: {
                title: this.$t('All ideas'),
                shortdes: this.$t(
                    'At the core of Zainiac we have the ideas submitted by our Zainers. These ideas are what enable us to remain a leading organization in the region.',
                ),
            },
            ideas: [],
            loadding: true,
            filters: {
                page_size: 6,
                page: 1,
            },
        }
    },
    created() {
        document.body.classList.add('ideas-page')
        IdeasHelper.listTrendIdea({ page_size: 5 })
        IdeasHelper.getBucketCategoriesList()
        this.changeFilters(this.filters)
    },
    methods: {
        changeFilters(filters) {
            if (filters.date) {
                this.filters.created = filters.date
            } else {
                delete this.filters.created
            }
            // if (filters.status) {
            //     this.filters.state = filters.status
            // } else {
            //     delete this.filters.state
            // }
            if (filters.idea_country) {
                this.filters.idea_country = filters.idea_country
            } else {
                delete this.filters.idea_country
            }
            if (filters.bucket) {
                this.filters.category_title = filters.bucket
            } else {
                delete this.filters.category_title
            }
            this.filters.state = 'approved,implemented,planned,funded'
            this.filters.active = 'true'
            // this.filters.category_published = 'true'
            this.filters.page = 1
            this.ideas = []
            this.getIdeas()
        },
        getNextData() {
            window.onscroll = () => {
                let bottomOfWindow =
                    document.documentElement.scrollTop + window.innerHeight >
                    document.documentElement.offsetHeight - 600
                if (bottomOfWindow && !this.loadding) {
                    let next = this.getNextPage(this.ideasList.next)
                    if (next) {
                        this.loadding = true
                        this.filters.page = next
                        IdeasHelper.listIdea(this.filters)
                    }
                }
            }
        },
        getIdeas() {
            this.loadding = true
            IdeasHelper.listIdea(this.filters)
        },
    },
    mounted() {
        this.getNextData()
    },
    computed: {
        ...mapGetters(['ideasList', 'ideasTrendList', 'bucketCategoriesList']),
    },
    watch: {
        ideasList() {
            let ideas = _.chunk(this.ideasList.results, 2)
            let allIdeas = _.concat(this.ideas, ideas)
            this.ideas = cloneDeep(allIdeas)
            this.loadding = false
        },
    },
    destroyed() {
        document.body.classList.remove('ideas-page')
    },
}
</script>

<style lang="scss" scoped>
/deep/.content-wrapper {
    .page__head {
        min-height: auto;
        &--title {
            color: var(--secondary);
        }
        @media screen and (max-width: 1700px) {
            margin-left: rem(0px);
        }
        @media screen and (max-width: 991px) {
            margin-left: 0;
        }
    }
}
/deep/.page-banner {
    & > img {
        object-fit: cover;
        height: 100%;
    }
    --h: var(--bannerHeight);
    @media screen and (max-width: 1600px) {
        --h: var(--bannerHeight);
    }
    @media screen and (max-width: 991px) {
        --h: var(--bannerHeight);
    }
    @media screen and (max-width: 767px) {
        img {
            object-position: right;
        }
    }
    @media screen and (max-width: 575px) {
        img {
            object-position: 60% center;
        }
    }
    &::after {
        background: $primary;
    }
}
.ideas {
    .page-contents {
        min-height: 550px;
    }
    &__latest {
        @media screen and (min-width: 1200px) {
            padding-top: rem(110px);
        }
        /deep/.greyBg {
            --h: 405px;
            width: 500px;
        }
        .row {
            padding-bottom: rem(65px);
            position: relative;
            z-index: 2;
        }

        .section-title {
            margin-bottom: rem(50px);
        }
        .section-subtitle {
            font-weight: 600;
            color: #2a2a2a;
        }
        &--rowWrapper {
            position: relative;
            /deep/.greyBg {
                --h: 530px;
                width: 500px;
                left: auto;
                right: 0px;
                top: 50%;
                &:before {
                    right: -100%;
                    left: auto;
                }
                @media screen and (max-width: 767px) {
                    --h: 670px;
                }
            }
            &:nth-child(odd) {
                /deep/.greyBg {
                    left: 0;
                    right: auto;
                    &:before {
                        left: -100%;
                        right: auto;
                    }
                }
            }
            &:last-child {
                /deep/.greyBg {
                    display: none !important;
                }
            }
        }
        &--row {
            display: flex;
            justify-content: space-between;
            margin-bottom: rem(120px);
            align-items: unset;
            @media screen and (max-width: 767px) {
                margin-bottom: rem(20px);
                flex-direction: column;
            }
        }
        &--items {
            flex: 0 0 50%;
            > div {
                height: 100%;
            }
            &:not(:last-child) {
                margin-right: rem(30px);
            }
            @media screen and (max-width: 991px) {
                flex: 0 0 100%;
                max-width: 100%;
            }
            @media screen and (max-width: 767px) {
                &:not(:last-child) {
                    margin-right: 0;
                }
            }
        }
        /deep/ {
            @media screen and (max-width: 1599px) {
                .listing {
                    &__item {
                        &--image img {
                            width: 200px;
                            height: 200px;
                        }
                    }
                }
            }
            @media screen and (min-width: 1600px) {
                .listing {
                    &__item {
                        &--contents {
                            max-width: 55% !important;
                            flex: 0 0 55% !important;
                        }
                        &--image img {
                            width: 235px;
                            height: 235px;
                        }
                    }
                }
            }
            @media screen and (max-width: 767px) {
                .listing {
                    &__item {
                        &--image {
                            top: rem(-50px) !important;
                        }
                        &--contents {
                            max-width: 100% !important;
                            flex: 0 0 100% !important;
                        }
                    }
                }
            }
        }
    }
}

// ar style
.ar {
    .ideas {
        &__latest {
            &--rowWrapper {
                position: relative;
                /deep/.greyBg {
                    right: auto;
                    left: 0px;
                    &:before {
                        left: -100%;
                        right: auto;
                    }
                }
                &:nth-child(odd) {
                    /deep/.greyBg {
                        right: 0;
                        left: auto;
                        &:before {
                            right: -100%;
                            left: auto;
                        }
                    }
                }
            }
            &--items {
                &:not(:last-child) {
                    margin-left: rem(30px);
                    margin-right: 0;
                }
                @media screen and (max-width: 767px) {
                    &:not(:last-child) {
                        margin-left: 0;
                    }
                }
            }
        }
        /deep/.content-wrapper {
            .page__head {
                @media screen and (max-width: 1700px) {
                    margin-right: rem(200px);
                    margin-left: 0;
                }
                @media screen and (max-width: 991px) {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>
